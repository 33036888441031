import { Locale } from '../i18n';

export const faucetTranslation = {
  [Locale.en]: {
    faucet: {
      title: 'Faucet UI',
      addressPlaceholder: 'Enter wallet address',
      button:
        'Give me {value} {token} {value, plural, =1 {Token} other {Tokens}}',
    },
  },
};
