import { Milliseconds } from '@ankr.com/chain-tools-types';
import { notification, queryFnNotifyWrapper } from '@ankr.com/chain-tools-ui';
import { toChecksumAddress } from 'web3-utils';

import { faucetApi } from '../../api/faucetApi';
import { API_ENDPOINT } from '../../common/const/environment';

export interface IFaucetFormPayload {
  address: string;
}

const NOTIFICATION_DURATION: Milliseconds = 20000;

export const { useClaimTokensMutation } = faucetApi.injectEndpoints({
  endpoints: build => ({
    claimTokens: build.mutation<null, IFaucetFormPayload>({
      queryFn: queryFnNotifyWrapper<IFaucetFormPayload, never, null>(
        async (payload: IFaucetFormPayload) => {
          const response = await fetch(`${API_ENDPOINT}/claim`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
              address: toChecksumAddress(payload.address),
            }),
          });

          const result = await response.json();

          notification({
            type: response.status !== 200 ? 'error' : 'success',
            message: result.msg,
            autoClose: NOTIFICATION_DURATION,
          });

          return { data: null };
        },
      ),
    }),
  }),
});
