import { ChangeEvent, JSX, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ImageX } from '@ankr.com/chain-tools-ui';
import { LoadingButton, TextField } from '@ankr.com/ui';
import { Box, Container, Typography } from '@mui/material';
import { isAddress } from 'web3-utils';

import {
  RECEIVING_TOKEN_AMOUNT,
  RECEIVING_TOKEN_NAME,
} from '../../../common/const/values';
import { useTranslation } from '../../../i18n';
import {
  IFaucetFormPayload,
  useClaimTokensMutation,
} from '../../api/claimTokens';
import { faucetTranslation } from '../../translation';
import imgUrl from './assets/faucet.png';
import imgUrl2x from './assets/faucet@2x.png';
import { useFaucetStyles } from './useFaucetStyles';

export function Faucet(): JSX.Element {
  const { classes } = useFaucetStyles();

  const { keys, t } = useTranslation(faucetTranslation);

  const { control, handleSubmit, setValue } = useForm<IFaucetFormPayload>({
    defaultValues: { address: '' },
  });

  const [claimTokens, { isLoading }] = useClaimTokensMutation();

  const onSubmit = useCallback(
    (payload: IFaucetFormPayload) => {
      void claimTokens(payload);
    },
    [claimTokens],
  );

  const handleAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue('address', event.target.value.trim());
    },
    [setValue],
  );

  return (
    <Container className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.titleWrap}>
          <ImageX
            imgUrl={imgUrl}
            img2xUrl={imgUrl2x}
            className={classes.titleImg}
          />
          <Typography variant="h4" className={classes.title}>
            {t(keys.faucet.title)}
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
          className={classes.form}
        >
          <Controller
            name="address"
            rules={{
              required: t('validation.required'),
              validate: v => isAddress(v) || t('validation.invalid-address'),
            }}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error ? error.message : null}
                error={!!error}
                onChange={handleAddressChange}
                value={value}
                fullWidth
                placeholder={t(keys.faucet.addressPlaceholder)}
              />
            )}
          />

          <LoadingButton
            loading={isLoading}
            size="large"
            className={classes.submitBtn}
            type="submit"
          >
            {t(keys.faucet.button, {
              value: RECEIVING_TOKEN_AMOUNT,
              token: RECEIVING_TOKEN_NAME,
            })}
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}
