import { Locale } from '../../../i18n';

export const footerTranslation = {
  [Locale.en]: {
    footer: {
      copyright: '©{year}. {project} All rights reserved',
      contractVerifier: 'Contract Verifier',
    },
  },
};
