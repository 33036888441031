import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';

import { LOGO_LINK } from '../../../common/const/values';
import { useHeaderStyles } from './useHeaderStyles';

export function Header() {
  const { classes } = useHeaderStyles();

  return (
    <Container className={classes.container}>
      <Link to={LOGO_LINK}>
        <img
          src={`${import.meta.env.VITE_HOMEPAGE}${import.meta.env.VITE_PUBLIC_DIR}/logo.svg`}
          alt={import.meta.env.VITE_PROJECT_NAME}
          className={classes.logo}
        />
      </Link>
    </Container>
  );
}
