import React, { JSX } from 'react';
import { generatePath, Route } from 'react-router-dom';
import { createRouteConfig } from '@ankr.com/utils/createRouteConfig';

import { LayoutDefault } from '../layout/components/LayoutDefault';
import { Faucet } from './screens/Faucet';

const PATH = '/';

export const FaucetRoutesConfig = createRouteConfig(
  {
    Faucet: {
      path: PATH,
      generatePath: () => generatePath(PATH),
    },
  },
  PATH,
);

export function getFaucetRoutes(): JSX.Element {
  return (
    <Route
      path={FaucetRoutesConfig.root}
      element={
        <LayoutDefault>
          <Faucet />
        </LayoutDefault>
      }
    />
  );
}
