import React, { JSX, ReactNode } from 'react';
import { Box } from '@mui/material';

import { Footer } from '../Footer';
import { Header } from '../Header';
import { useLayoutDefaultStyles } from './useLayoutDefaultStyles';

export interface ILayoutDefaultProps {
  children?: ReactNode;
}

export function LayoutDefault({ children }: ILayoutDefaultProps): JSX.Element {
  const { classes } = useLayoutDefaultStyles();

  return (
    <Box className={classes.root}>
      <Header />
      <Box component="main" className={classes.main}>
        <Box className={classes.mainContent}>{children}</Box>
        <Footer />
      </Box>
    </Box>
  );
}
