import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const sentryInit = (sentryDsn?: string): void => {
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};
