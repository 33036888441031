import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import { Locale } from './types';

export interface I18nSlice {
  locale: Locale;
}

const initialState: I18nSlice = {
  locale: Locale.en,
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<Locale>) => {
      // eslint-disable-next-line no-param-reassign
      state.locale = action.payload;
    },
  },
});

export const i18nReducer = persistReducer(
  {
    key: 'i18n',
    storage,
  },
  i18nSlice.reducer,
);

export const { setLocale } = i18nSlice.actions;
