import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

import { locales } from '../const';
import { Locale } from '../types';
import { useLocale } from './useLocale';

interface IUseLocaleInit {
  isLocaleReady: boolean;
}

export const useLocaleInit = (): IUseLocaleInit => {
  const [isLocaleReady, setLocaleReady] = useState(false);

  const { locale } = useLocale();

  useEffect(() => {
    setLocaleReady(false);

    async function loadLocale(): Promise<void> {
      await intl.init({
        currentLocale: locale,
        locales,
        fallbackLocale: Locale.en,
      });

      setLocaleReady(true);
    }

    void loadLocale();
  }, [locale]);

  return {
    isLocaleReady,
  };
};
